<template>
	<ManageYourAccountTemplate :page-title="$t('title')">
		<ChangeLanguage
			automation-id="changeLanguage"
			:language="preferredLanguage"
			@update="updateLanguage"
			@save="saveLanguage($event)"
		/>
	</ManageYourAccountTemplate>
</template>

<script>
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { DASHBOARD, MANAGE_YOUR_ACCOUNT, PERSONAL_INFORMATION } from '@/constants/Routes';
import ManageYourAccountTemplate from '../ManageYourAccountTemplate.vue';
import ChangeLanguage from '@/components/manage-your-account/personal-information/ChangeLanguage.vue';
import MemberCardInfo from '@/models/MemberCardInfo';

export default {
	name: 'ChangeLanguagePage',
	components: {
		ManageYourAccountTemplate,
		ChangeLanguage
	},
	mixins: [BreadcrumbsManager],
	data() {
		return {
			email: '',
			preferredLanguage: ''
		};
	},

	async mounted() {
		this.email = sessionStorage.getItem('email');
		this.preferredLanguage = await MemberCardInfo.getPreferredLanguage(this.email);

		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.manageYourAccount', 'en'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.personalInformation', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.manageYourAccount', 'fr'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.personalInformation', 'fr') }
			]
		);
	},

	methods: {
		updateLanguage(language) {
			this.preferredLanguage = language;
		},

		async saveLanguage(language) {
			try {
				this.$store.dispatch('updateLoading', true);
				await MemberCardInfo.updatePreferredLanguage(
					this.email,
					sessionStorage.getItem('apiToken'),
					this.$store.state.i18n.locale,
					language
				);
				this.$store.dispatch('updateLoading', false);
				this.$router.push({ name: PERSONAL_INFORMATION, params: { hasSucceeded: true } });
			} catch (error) {
				this.$store.dispatch('error', {
					name: 'change-language-error',
					title: this.$t('error.wentWrongHeader'),
					err: this.$t('error.wentWrong')
				});
			}
		}
	}
};
</script>

<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "manageYourAccount": "Manage your account",
      "personalInformation": "Personal Information"
    },
    "title": "Personal Information",
    "error": {
      "wentWrongHeader": "Something went Wrong",
      "wentWrong": "Oops, something went wrong. Please try again later."
    }
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "manageYourAccount": "Gérer votre compte",
      "personalInformation": "Renseignements personnels"
    },
    "title": "Renseignements personnels",
    "error": {
      "wentWrongHeader": "[Fr]Something went Wrong",
      "wentWrong": "Nous sommes désolés, une erreur s’est produite. Veuillez réessayer plus tard."
    }
  }
}
</i18n>
