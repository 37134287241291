<template>
	<BaseCard automation-id="changeEmail" :title="$t('title')">
		<BCardText>
			{{ $t('text.offerInBothLanguages') }} <br />
			{{ $t('text.whichDoYouPrefer') }}
		</BCardText>

		<BaseForm automation-id="changeLanguageForm">
			<BaseRadioButtonGroup
				:value="language"
				:options="languageOptions"
				stacked
				:automation-id="getAutomationId('languagePreference')"
				@input="updateLanguage"
			/>
			<BaseButton
				ref="btnSubmit"
				:label="$t('label.save')"
				variant="primary"
				:automation-id="getAutomationId('save')"
				type="submit"
				@click="onSave"
			/>
			<BaseButton
				ref="btnCancel"
				:label="$t('label.cancel')"
				variant="link"
				:automation-id="getAutomationId('cancel')"
				@click="onCancel"
			/>
		</BaseForm>
	</BaseCard>
</template>

<script>
import IdMixin from '@/mixins/id';
import { PERSONAL_INFORMATION } from '@/constants/Routes';
import { BCardText } from 'bootstrap-vue';
import BaseForm from '@/components/common/base/BaseForm.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseRadioButtonGroup from '@/components/common/base/BaseRadioButtonGroup.vue';

export default {
	name: 'ChangeLanguage',
	components: {
		BCardText,
		BaseForm,
		BaseButton,
		BaseCard,
		BaseRadioButtonGroup
	},
	mixins: [IdMixin],

	props: {
		language: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			newLanguage: null
		};
	},

	computed: {
		/**
		 * Returns the list of options for radio buttons
		 * @returns {array}
		 */
		languageOptions() {
			return [
				{ value: 'en', text: this.$t('label.english') },
				{ value: 'fr', text: this.$t('label.french') }
			];
		}
	},

	/** Initialize the newLanguage */
	created() {
		this.newLanguage = this.language;
	},

	methods: {
		/** Emits to the parent component the member's new language preference. */
		updateLanguage: function (value) {
			this.newLanguage = value;
			this.$emit('update', value);
		},

		/** Cancel and return the user to the previous screen. */
		onCancel() {
			this.$router.push({ name: PERSONAL_INFORMATION });
		},

		onSave() {
			this.$emit('save', this.newLanguage);
		}
	}
};
</script>

<i18n>
{
  "en": {
    "title": "Language Preferences",
    "text": {
      "offerInBothLanguages": "We can communicate plan changes in both English and French.",
      "whichDoYouPrefer": "Which language would you like us to use?"
    },
    "label": {
      "save": "Save",
      "cancel": "Cancel",
      "english": "English",
      "french": "French"
    }
  },
  "fr": {
    "title": "Langue de préférence",
    "text": {
      "offerInBothLanguages": "Nous pouvons communiquer les changements au régime en français et en anglais.",
      "whichDoYouPrefer": "Quelle langue souhaitez-vous que nous utilisions?"
    },
    "label": {
      "save": "Enregistrer",
      "cancel": "Annuler",
      "english": "Anglais",
      "french": "Français"
    }
  }
}
</i18n>
